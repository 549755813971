import * as React from "react"
import styled from 'styled-components'

const StyledCard = styled.article`
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 180px auto 56px;
	background-color: #eee;

    @media (max-width: 656px) {
        margin: 0 4.923vw;
        grid-template-rows: 55.385vw auto 17.231vw;
    }
`

const StyledLogoContainer = styled.div`
	background-color: ${ ({ bgColor }) => bgColor};
    display: flex;
    justify-content: space-around;
    align-items: center;
`

const StyledLogo = styled.img`
    max-height: 116px;
    width: 240px;
    padding: 32px 40px;
    object-fit: contain;

    @media (max-width: 656px) {
        height: auto;
        width: 60vw;
        padding: 0;
    }
`

const StyledDescription = styled.div`
    text-align: justify;
    padding: 32px;

    & > p {
        font-size: 12px;
        line-height: 22px;
        font-weight: 300;
        margin: 0 0 24px 0;
    }

    & > p:last-child {
        margin: 0 !important;
    }

    @media (max-width: 656px) {
        padding: 9.846vw;
        
        & > p {
            font-size: 3.692vw;
            line-height: 6.769vw;
            font-weight: 300;
            margin: 0 0 7.385vw 0;
        }
    }
`

const StyledLink = styled.a`
    display: block;
    margin: 0 24px 0;
    font-size: 12px;
    text-align: right;
    text-decoration: none;
    color: black;

    @media (max-width: 656px) {
        margin: 0 7.385vw 0;
        font-size: 3.692vw;
    }
`

const PortfolioItem = ({
    imgUrl,
    backgroundColor,
    richText,
    url
}) => {
	return (
		<>
			<StyledCard>
                <StyledLogoContainer bgColor={backgroundColor}>
                    <StyledLogo src={imgUrl}/>
                </StyledLogoContainer>
                <StyledDescription dangerouslySetInnerHTML={{
                    __html: richText
                    }}
                />
                <StyledLink href={url} target={'_blank'}>
                    website →
                </StyledLink>
            </StyledCard>
		</>
	)
}

export default PortfolioItem
