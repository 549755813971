import BlockchainCuties from './images/Blockchaincuties.png'
import ChainGuardians from './images/Chainguardians.png'
import Dhedge from './images/Dhedge.png'
import Horizon from './images/Horizon.png'
import Polkadex from './images/Polkadex.png'
import Paralink from './images/Paralink.png'
import Archer from './images/Archer.png'
import Nsure from './images/Nsure.png'
import Reef from './images/Reef.png'
import Unbound from './images/Unbound.png'
import Zeroswap from './images/zeroswap.png'
import RampDefi from './images/RampDefi.png'
import TerraVirtua from './images/TerraVirtua.png'
import OneInch from './images/1inch.png'
import ApyFinance from './images/ApyFinance.png'
import PowerTrade from './images/PowerTrade.png'
import Manta from './images/Manta.png'
import Alkemi from './images/Alkemi.svg'
import PublicMint from './images/Public Mint.svg'
import Polkastarter from './images/Polkastarter.svg'
import Bifrost from './images/Bifrost.svg'
import Razor from './images/Razor.svg'
import Sifchain from './images/Sifchain.svg'
import Plasmafinance from './images/Plasmafinance.svg'
import OpenDeFi from './images/OpenDeFi.svg'
import HaloDAO from './images/HaloDao.jpg'
import Efinity from './images/Efinity.svg'
import Akash from './images/Akash.svg'
import Zerox from './images/Zerox.svg'
import Arweave from './images/Arweave.svg'
import Bancor from './images/Bancor.png'
import Cere from './images/Cere.png'
import Coinflex from './images/Coinflex.svg'
import Ethalend from './images/Ethalend.svg'
import Ethermon from './images/Ethermon.png'
import Filecoin from './images/Filecoin.svg'
import FTX from './images/FTX.png'
import Handshake from './images/Handshake.svg'
import Hashflow from './images/Hashflow.png'
import Irisnet from './images/Irisnet.png'
import Kalmar from './images/Kalmar.png'
import Kyber from './images/Kyber.svg'
import Mobilecoin from './images/Mobilecoin.png'
import Oddz from './images/Oddz.svg'
import Polkadot from './images/Polkadot.svg'
import Sentinel from './images/Sentinel.svg'
import Sigmadex from './images/Sigmadex.svg'
import Solana from './images/Solana.svg'
import Synthetix from './images/Synthetix.svg'
import Thegraph from './images/Thegraph.svg'
import Unmarshal from './images/Unmarshal.svg'
import Zed from './images/Zed.png'
import Zeitgeist from './images/Zeitgeist.png'
import Zilliqa from './images/Zilliqa.png'
import Vega from './images/Vega.svg'
import Ramifi from './images/Ramifi.png'
import Saffron from './images/Saffron.png'
import Trodl from './images/Trodl.png'
import Mixsome from './images/Mixsome.svg'
import Equalizer from './images/Equalizer.png'
import Unifarm from './images/Unifarm.png'
import Metis from './images/Metis.png'
import Claystack from './images/Claystack.png'
import Scaleswap from './images/Scaleswap.svg'
import Aioz from './images/Aioz.svg'
import Monoswap from './images/Monoswap.png'
import Ispolink from './images/Ispolink.svg'
import Dfyn from './images/Dfyn.svg'
import Cryption from './images/Cryption.png'
import Mintlayer from './images/Mintlayer.svg'
import Paraswap from './images/Paraswap.svg'
import Sienna from './images/Sienna.svg'
import Panther from './images/Panther.svg'
import Thedapplist from './images/Thedapplist.png'
import Onomy from './images/Onomy.png'
import Naos from './images/Naos.svg'
import Benqi from './images/Benqi.png'
import Biconomy from './images/Biconomy.jpg'
import Raze from './images/Raze.png'
import Parastate from './images/Parastate.png'
import Parami from './images/Parami.png'
import Doingud from './images/Doingud.png'
import Drops from './images/Drops.png'
import Bitcountry from './images/Bitcountry.png'
import Handle from './images/Handle.png'
import XDefi from './images/XDefi.png'
import Aleph from './images/Aleph.svg'
import Deversifi from './images/Deversifi.png'
import Formation from './images/Formation.png'
import Synthetify from './images/Synthetify.svg'
import Apricot from './images/Apricot.png'
import Solanium from './images/Solanium.svg'
import Mercurial from './images/Mercurial.svg'
import Solrise from './images/Solrise.svg'
import Dexlab from './images/Dexlab.svg'
import WilderWorld from './images/WilderWorld.png'
import Nafter from './images/Nafter.svg'
import GameStarter from './images/GameStarter.svg'
import Aurory from './images/Aurory.jpg'
import Hedgehog from './images/Hedgehog.svg'
import SolRazr from './images/SolRazr.svg'
import Layerzero from './images/Layerzero.svg'
import Eden from './images/Eden.svg'
import Bloktopia from './images/Bloktopia.png'
import Opgames from './images/Opgames.svg'
import Portal from './images/Portal.png'
import Mavia from './images/Mavia.png'


const portfolioItems = [
    {
        active: true,
        imgUrl: Zerox,
        backgroundColor: '#fff',
        richText: `
            <p>The liquidity endpoint for DeFi.</p>
        `,
        url: 'https://0x.org/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Bancor,
        backgroundColor: '#fff',
        richText: `
            <p>The Bancor Network is a set of smart contracts that manages the conversion flow between tokens, including tokens on different blockchains.</p>
        `,
        url: 'https://app.bancor.network/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Kyber,
        backgroundColor: '#fff',
        richText: `
            <p>Kyber is a blockchain-based liquidity protocol that aggregates liquidity from a wide range of reserves, powering instant and secure token exchange in any decentralized application.</p>
        `,
        url: 'https://kyber.network/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Filecoin,
        backgroundColor: '#000',
        richText: `
            <p>Filecoin is a decentralized storage network designed to store humanity's most important information.</p>
        `,
        url: 'https://filecoin.io/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Zilliqa,
        backgroundColor: '#000',
        richText: `
            <p>A blockchain platform that empowers innovators to build a new digital economy.</p>
        `,
        url: 'https://www.zilliqa.com/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Synthetix,
        backgroundColor: '#fff',
        richText: `
            <p>Synthetix is the backbone for derivatives trading in DeFi, allowing anyone, anywhere to gain on-chain exposure to a vast range of assets.</p>
        `,
        url: 'https://synthetix.io/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: FTX,
        backgroundColor: '#000',
        richText: `
            <p>FTX is a cryptocurrency exchange built by traders, for traders. FTX offers innovative products including industry-first derivatives, options, volatility products and leveraged tokens.</p>
        `,
        url: 'https://ftx.com/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Coinflex,
        backgroundColor: '#000',
        richText: `
            <p>Physically delivered crypto futures exchange.</p>
        `,
        url: 'https://coinflex.com/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Mobilecoin,
        backgroundColor: '#536782',
        richText: `
            <p>Private payments for everyone.</p>
        `,
        url: 'https://www.mobilecoin.com/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Vega,
        backgroundColor: '#000',
        richText: `
            <p>Vega is a protocol for creating and trading derivatives on a fully decentralized network.</p>
        `,
        url: 'https://vega.xyz/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Handshake,
        backgroundColor: '#fff',
        richText: `
            <p>Decentralized naming and certificate authority.</p>
        `,
        url: 'https://handshake.org/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Arweave,
        backgroundColor: '#fff',
        richText: `
            <p>Decentralized data storage.</p>
        `,
        url: 'https://www.arweave.org/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Thegraph,
        backgroundColor: '#000',
        richText: `
            <p>The Graph is an indexing protocol for querying networks like Ethereum and IPFS. Anyone can build and publish open APIs, called subgraphs, making data easily accessible.</p>
        `,
        url: 'https://thegraph.com/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: ApyFinance,
        backgroundColor: '#14063A',
        richText: `
            <p>APY.Finance is a smart contract protocol that functions like a robo-advisor for yield-farming. It offers a single place for users to deposit their tokens and the protocol will then do all the work in pooling liquidity from other users, rebalancing the portfolio, and distributing gas costs evenly. In short, APY.Finance makes it cheaper for users to participate in yield-farming.</p>
        `,
        url: 'https://apy.finance/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Dhedge,
        backgroundColor: '#333',
        richText: `
            <p>dHEDGE is a non-custodial, decentralized asset management protocol for synthetic assets. The protocol enables investors to mimic the investment strategies of top performing asset managers whilst empowering the latter through its unique asset pool mechanisms. dHEDGE’s goal is to democratize investing and become the one-stop location for managing investment activities on the Ethereum Blockchain.</p>
        `,
        url: 'https://www.dhedge.org/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: RampDefi,
        backgroundColor: 'white',
        richText: `
            <p>RAMP DEFI focuses on developing a suite of products which shall act as the “building blocks” to power the RAMP ecosystem and cross-chain value accretion. rMint takes in non-ERC20 digital assets as collateral and mints rUSD against this collateral with a safe liquidation buffer.</p>
        `,
        url: 'https://rampdefi.com/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: PowerTrade,
        backgroundColor: '#020203',
        richText: `
            <p>PowerTrade is a crypto options and structured products trading platform. The platform enables the trading of Bitcoin in a quick, intuitive and seamless manner on all mobile devices.</p>
        `,
        url: 'https://power.trade/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Razor,
        backgroundColor: '#40429B',
        richText: `
            <p>Razor Network is a decentralized oracle network, which connects smart contracts with real-world off-chain data in a fast, robust and secure way. The core of Razor Network is a set of smart contracts that can run on any Ethereum compatible blockchain. Razor network is blockchain agnostic and will be integrated with multiple blockchains./p>
        `,
        url: 'https://razor.network/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Zeroswap,
        backgroundColor: '#1741C3',
        richText: `
            <p>ZeroSwap.io is a Multi-Chain, Zero-fee, On-chain Trading protocol with Liquidity Mining, DeFi Token Offering, and DEX Aggregation. The protocol will reward users every time they make an on-chain trade and provide liquidity to existing DEXes. All transactions would be Gasless with Zero Trade Fee.</p>
        `,
        url: 'https://zeroswap.io/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: OpenDeFi,
        backgroundColor: 'navy',
        richText: `
            <p>OpenDeFi is a platform that encourages the tokenization of real-world assets such as gold, stocks, commodities, exchange-traded funds (ETFs), silver, and many more.</p>
            <p>It brings real-world assets to the blockchain world, thus, increasing the confidence of conservative investors and firms to cross over to the other side.</p>
        `,
        url: 'https://opendefi.finance/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Nsure,
        backgroundColor: 'white',
        richText: `
            <p>Nsure is an open insurance platform for Open Finance. The project borrows the idea of Lloyd's London, a market place to trade insurance risks, where premiums are determined by a Dynamic Pricing Model. Capital mining will be implemented to secure capital required to back the risks at any point of time.</p>
        `,
        url: 'https://nsure.network/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Horizon,
        backgroundColor: 'white',
        richText: `
            <p>Horizon Finance allows users to deposit their funds and compete for preferential payment by capping their yield, thereby outperforming or underperforming the benchmark interest rate.</p>
        `,
        url: 'https://horizon.finance/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Archer,
        backgroundColor: '#11141C',
        richText: `
            <p>Archer DAO is the tool that allows network owners to own the network and make decisions. The smart contract architecture is designed for flexibility given the technical challenges of managing a highly dynamic system</p>
        `,
        url: 'https://www.archerdao.io/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Eden,
        backgroundColor: '#11141C',
        richText: `
            <p>Eden is a priority transaction network that protects traders from frontrunning, aligns incentives for block producers, and redistributes miner extractable value.</p>
        `,
        url: 'https://www.edennetwork.io/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: OneInch,
        backgroundColor: '#0E141E',
        richText: `
            <p>1inch Exchange is a decentralized cryptocurrency exchange (DEX) aggregator used to buy or sell cryptocurrency across multiple DEXs. Orders are split across multiple DEXs to find the best market price available by using arbitrage bots.</p>
            <p>This can save a great deal of money when placing large orders in illiquid markets.</p>
        `,
        url: 'https://1inch.exchange/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Paraswap,
        backgroundColor: '#000',
        richText: `
            <p>ParaSwap aggregates DeFi liquidity and offers the best market rates.</p>
        `,
        url: 'https://paraswap.io/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Plasmafinance,
        backgroundColor: 'white',
        richText: `
            <p>PlasmaPay is a global payment and remittance platform for people and digital business. Here you can securely store crypto and fiat currency, buy and exchange popular digital assets, make and receive payments, build your own payment network with zero-fee transactions. money for the global peer-to-peer economy.</p>
        `,
        url: 'https://plasma.finance/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Unbound,
        backgroundColor: 'white',
        richText: `
            <p>Unbound is a Decentralized Finance (DeFi) platform that works on the derivative layer of Automated Market Makers (AMMs). Unbound enables users to unlock liquidity from other platforms such as Uniswap, Balancer, Curve, etc.</p>
            <p>Provide a debt-free liquidity provision system.</p>
        `,
        url: 'https://unbound.finance/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: PublicMint,
        backgroundColor: '#151B61',
        richText: `
            <p>Public Mint provides blockchain-based payment solutions. It provides a fiat-native blockchain network to connect crypto-projects and traditional finance sectors. It provides native fiat currency on a programmable blockchain network to promote crypto transactions using fiat currency stability.</p>
        `,
        url: 'https://publicmint.com/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Alkemi,
        backgroundColor: '#3708BA',
        richText: `
            <p>Alkemi is a business-to-contract network that bridges Decentralized Finance ('DeFi') to Centralized Institutions ('CeFi'). We are fostering the next stage of growth for decentralized finance by connecting these bifurcated markets. We unlock centralized liquidity through trust, composability, and transparency.</p>
        `,
        url: 'https://alkemi.network/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Hashflow,
        backgroundColor: '#fff',
        richText: `
            <p>Trade with the world's best market makers on DeFi.</p>
        `,
        url: 'https://hashflow.com/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Trodl,
        backgroundColor: '#fff',
        richText: `
            <p>Trodl is a reputation based crypto social media platform empowering its users with accurate and verifiable crypto information in a democratized way.</p>
        `,
        url: 'https://trodl.com/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Oddz,
        backgroundColor: '#fff',
        richText: `
            <p>Oddz is the Multi-chain options trading platform on Binance Smart Chain, Polkadot & Ethereum.</p>
        `,
        url: 'https://oddz.fi/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Ethalend,
        backgroundColor: '#000',
        richText: `
            <p>ETHA Lend is a composable protocol that abstracts the complexity in DeFi to provide algorithmically optimal yield.</p>
        `,
        url: 'https://www.ethalend.org/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Unmarshal,
        backgroundColor: '#fff',
        richText: `
            <p>Multichain DeFi Data Network. The easiest way to query Blockchain data from Ethereum, Binance Smart Chain and Polkadot.</p>
        `,
        url: 'https://unmarshal.io/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Kalmar,
        backgroundColor: '#fff',
        richText: `
            <p>Kalmar is a decentralized bank powered by non-fungible token technology and advanced gamification models. Our goal is to make finance transparent, engaging and accessible.</p>
        `,
        url: 'https://kalmar.io/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Ramifi,
        backgroundColor: '#373A3F',
        richText: `
            <p>Ramifi is a synthetic asset protocol who's main goal is to denominate inflation and hedge against it.</p>
        `,
        url: 'https://ramifi.org/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: HaloDAO,
        backgroundColor: '#fff',
        richText: `
            <p>HaloDAO is a stablecoin protocol for South East Asia (SEA) that allows minting of region-specic synthetic stablecoins backed by DAI, with full on & off ramp capabilities.</p>
        `,
        url: 'https://halodao.com/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Saffron,
        backgroundColor: '#F4F5F7',
        richText: `
            <p>Saffron is an asset collateralization platform where liquidity providers have access to dynamic exposure by selecting customized risk and return profiles.</p>
        `,
        url: 'https://saffron.finance/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Mixsome,
        backgroundColor: '#fff',
        richText: `
            <p>Mixsome is a DeFi strategy aggregator built on xDAI to maximise yield generation.</p>
        `,
        url: 'https://mixsome.cash/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Equalizer,
        backgroundColor: '#000',
        richText: `
            <p>Equalizer is the first dedicated platform that equalizes decentralized markets, designed as a marketplace for flash loans.</p>
        `,
        url: 'https://equalizer.finance/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Unifarm,
        backgroundColor: '#000',
        richText: `
            <p>UniFarm is the first staking solution allowing users to stake one token whilst earning farming rewards from other pools.</p>
        `,
        url: 'https://unifarm.co/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Metis,
        backgroundColor: '#000',
        richText: `
            <p>MetisDAO is a Layer2 DAO protocol leveraging current DAO infrastructure, opening up access to a full suite of administration and community tools for Web 3.0.</p>
        `,
        url: 'https://metisdao.org/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Scaleswap,
        backgroundColor: '#fff',
        richText: `
            <p>ScaleSwap is a fully decentralized IDO launchpad, harnessing the power of an advanced layer 2 blockchain scaling protocol.</p>
        `,
        url: 'https://scaleswap.io/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Aioz,
        backgroundColor: '#000',
        richText: `
            <p>AIOZ is a distributed content delivery network built on it's own blockchain.</p>
        `,
        url: 'https://aioz.network/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Monoswap,
        backgroundColor: '#fff',
        richText: `
            <p>Monoswap uses one liquidity pool and groups deposited tokens into a virtual pair with the vUSD stablecoin.</p>
        `,
        url: 'https://monox.finance/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Ispolink,
        backgroundColor: '#fff',
        richText: `
            <p>Ispolink is a cross-chain platform connecting firms with leading tech talent, powered by BSC.</p>
        `,
        url: 'https://ispolink.com/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Dfyn,
        backgroundColor: '#000',
        richText: `
            <p>DFYN aims to enable cheap, seamless cross-chain liquidity flows for the DeFi ecosystem using Matic Network and Polkadot technology.</p>
        `,
        url: 'https://www.dfyn.network/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Cryption,
        backgroundColor: '#000',
        richText: `
            <p>Fully gasless "Pancakeswap on Ethereum with L2."</p>
        `,
        url: 'https://cryption.network/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Mintlayer,
        backgroundColor: '#fff',
        richText: `
            <p>Mintlayer is a Bitcoin side-chain protocol, designed for creating decentralized financial markets.</p>
        `,
        url: 'https://www.mintlayer.org/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Panther,
        backgroundColor: '#fff',
        richText: `
            <p>Panther is an end-to-end privacy protocol for digital assets, which can be deployed in a compliant way on any public blockchain.</p>
        `,
        url: 'https://pantherprotocol.io/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Thedapplist,
        backgroundColor: '#fff',
        richText: `
            <p>The DApp List is a DApp enabler platform, encouraging developers to build on a decentralised platform.</p>
        `,
        url: 'https://thedapplist.com/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Onomy,
        backgroundColor: '#000',
        richText: `
            <p>Onomy is a multi-chain DEX bridging traditional and decentralized finance through Forex.</p>
        `,
        url: 'https://onomy.io/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Naos,
        backgroundColor: '#000',
        richText: `
            <p>NAOS finance is a DeFi lending protocol for real world assets.</p>
        `,
        url: 'https://naos.finance/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Aleph,
        backgroundColor: '#fff',
        richText: `
            <p>Aleph Zero is a Proof-of-Stake public blockchain with private smart contracts built from the first principles.</p>
        `,
        url: 'https://alephzero.org/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Deversifi,
        backgroundColor: '#fff',
        richText: `
            <p>DeversiFi is the easiest way to access DeFi opportunities on Ethereum: invest, trade, and send tokens without paying gas fees.</p>
        `,
        url: 'https://www.deversifi.com/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Formation,
        backgroundColor: '#000',
        richText: `
            <p>Welcome to Cross-Chain Risk Parity Smart Farming 2.0</p>
        `,
        url: 'https://formation.fi/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Benqi,
        backgroundColor: '#000',
        richText: `
            <p>Benqi is an algorithmic liquidity market protocol built on Avalanche.</p>
        `,
        url: 'https://benqi.fi/',
        category: 'main'
    },
    {
        active: false,
        imgUrl: Handle,
        backgroundColor: '#000',
        richText: `
            <p>Create & exchange multi-currency stablecoins. All backed by Ethereum.</p>
        `,
        url: 'https://handle.fi/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: XDefi,
        backgroundColor: '#4C73F6',
        richText: `
            <p>XDefi is a chain agnostic cryptocurrency wallet.</p>
        `,
        url: 'https://xdefi.io/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Claystack,
        backgroundColor: '#000',
        richText: `
            <p>ClayStack is a next-gen crypto staking platform allowing its users to stake their tokens and participate in the DeFi ecosystem at the same time.</p>
        `,
        url: 'https://claystack.com/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Biconomy,
        backgroundColor: '#fff',
        richText: `
            <p>Biconomy is a next-gen multichain relayer protocol reducing the friction for Web 3.0 integrations.</p>
        `,
        url: 'https://www.biconomy.io/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Layerzero,
        backgroundColor: '#fff',
        richText: `
            <p>An Omnichain Interoperability Protocol.</p>
        `,
        url: 'https://layerzero.network/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: Portal,
        backgroundColor: '#000',
        richText: `
            <p>Portal is a defi platform that blends the best of Bitcoin and Layer 2.</p>
        `,
        url: 'https://portaldefi.com/',
        category: 'main'
    },
    {
        active: true,
        imgUrl: TerraVirtua,
        backgroundColor: '#021A25',
        richText: `
            <p>Terra Virtua is the first fully immersive, blockchain-driven VR entertainment Platform, uniquely focusing on VR games, eSports, live events and broadcasts, combining the admission elements of Netflix (monthly subscription for all content), and pay per attraction to create a unique, immersive platform.</p>
        `,
        url: 'https://www.terravirtua.io/',
        category: 'gaming'
    },
    {
        active: true,
        imgUrl: Aurory,
        backgroundColor: '#fff',
        richText: `
        <p>Aurory is a PvE/PvP gaming project using NFTs, powered by Solana and Serum.</p>
        `,
        url: 'https://aurory.io/',
        category: 'gaming'
    },
    {
        active: true,
        imgUrl: Bitcountry,
        backgroundColor: '#000',
        richText: `
        <p>Start your own metaverse, NFTs and token economy for your fans.</p>
        `,
        url: 'https://bit.country/',
        category: 'gaming'
    },
    {
        active: true,
        imgUrl: WilderWorld,
        backgroundColor: '#fff',
        richText: `
        <p>Wilder World is a decentralized artist DAO and liquid NFT marketplace.</p>
        `,
        url: 'https://www.wilderworld.com/',
        category: 'gaming'
    },
    {
        active: true,
        imgUrl: Efinity,
        backgroundColor: '#fff',
        richText: `
        <p>Next generation Blockchain built on Polkadot and the world's first cross-chain highway for non-fungible tokens.</p>
        `,
        url: 'https://enjin.io/',
        category: 'gaming'
    },
    {
        active: true,
        imgUrl: Bloktopia,
        backgroundColor: '#fff',
        richText: `
        <p>Bloktopia will provide an unprecedented VR experience for the crypto community, bringing users together all in one immersive and engaging environment.</p>
        `,
        url: 'https://www.bloktopia.com/',
        category: 'gaming'
    },
    {
        active: true,
        imgUrl: Mavia,
        backgroundColor: '#000',
        richText: `
        <p>Mavia is a Clash of Clans style game developed by Skrice Studios that requires you to build your army, attack and defend your base to conquer opponents.</p>
        `,
        url: 'https://mavia.com/',
        category: 'gaming'
    },
    {
        active: true,
        imgUrl: ChainGuardians,
        backgroundColor: '#131428',
        richText: `
            <p>ChainGuardians is a cryptocurrency-meets-anime-themed blockchain collectible game powered by non-fungible tokens (NFT).</p>
            <p>The goal of ChainGuardians is to introduce a gaming element in the blockchain ecosystem, implementing chain analysis technology and advanced game economics to govern the platform's mechanics.</p>
        `,
        url: 'https://chainguardians.io/',
        category: 'gaming'
    },
    {
        active: false,
        imgUrl: GameStarter,
        backgroundColor: '#fff',
        richText: `
        <p>New standard for indie game funding</p>
        `,
        url: 'https://www.gamestarter.co/',
        category: 'gaming'
    },
    {
        active: false,
        imgUrl: Nafter,
        backgroundColor: '#fff',
        richText: `
        <p>Nafter is an online market place for ceators and fans to buy, sell, mint & collect content NFTs - all in one web app.</p>
        `,
        url: 'https://nafter.io/landing',
        category: 'gaming'
    },
    {
        active: false,
        imgUrl: Doingud,
        backgroundColor: '#fff',
        richText: `
        <p>DoinGud is an NFT marketplace and social platform, focused on empowering artists, creators, collectors and curators.</p>
        `,
        url: 'https://doingud.com/',
        category: 'gaming'
    },
    {
        active: true,
        imgUrl: Opgames,
        backgroundColor: '#fff',
        richText: `
        <p>OP Games aims to be the center of Web3 gaming, with a vibrant and growing community of developers, players, and game owners.</p>
        `,
        url: 'https://opgames.org/',
        category: 'gaming'
    },
    {
        active: false,
        imgUrl: BlockchainCuties,
        backgroundColor: '#0056b3',
        richText: `
            <p>Blockchain Cuties is a collectible crypto game with adventures where you get to play with puppies, lizards, bear cubs, cats and other real and fantasy creatures alike. Each cutie is unique and 100% belongs to you. You get to collect them, breed them, test their skills in battles, arm them and even level them up.</p>
        `,
        url: 'https://blockchaincuties.com/',
        category: 'gaming'
    },
    {
        active: false,
        imgUrl: Zed,
        backgroundColor: '#fff',
        richText: `
            <p>The future of digital racehorse ownership is here. Race your way to the top and build your legacy today.</p>
        `,
        url: 'https://zed.run/',
        category: 'gaming'
    },
    {
        active: false,
        imgUrl: Ethermon,
        backgroundColor: '#fff',
        richText: `
            <p>Decentralized world of ether monsters.</p>
        `,
        url: 'https://ethermon.io/',
        category: 'gaming'
    },
    {
        active: false,
        imgUrl: Drops,
        backgroundColor: '#fff',
        richText: `
        <p>Drops.is is an NFT marketplace with staking, trustless loans and vaults.</p>
        `,
        url: 'https://drops.is/',
        category: 'gaming'
    },
    {
        active: true,
        imgUrl: Polkadot,
        backgroundColor: '#fff',
        richText: `
        <p>A scalable, interoperable & secure network protocol for the next web.</p>
        `,
        url: 'https://polkadot.network/',
        category: 'polkadot'
    },
    {
        active: true,
        imgUrl: Manta,
        backgroundColor: 'white',
        richText: `
        <p>Manta is a set of easy-to-use and secure (open source and auditable code) protocols based on Substrate frameworks. It uses zk-SNARK to provide complete end-to-end anonymity, high throughput, and high cross-chain interoperability (it supports Polkadot and its parachain mainstream cryptocurrencies and stable coins).</p>
        `,
        url: 'https://manta.network/',
        category: 'polkadot'
    },
    {
        active: true,
        imgUrl: Bifrost,
        backgroundColor: 'black',
        richText: `
        <p>Bifrost is the DeFi project for PoS tokens, that include staking and liquidity both. It allows to get vToken for operations and delivering liquidity, plus you got staking rewards all the time your DOT, KSM,EOS or other token blocked by the smart-contract.</p>
        <p>Bifrost is a Polkadot Parachain candidate.</p>
        `,
        url: 'https://bifrost.finance/',
        category: 'polkadot'
    },
    {
        active: true,
        imgUrl: Reef,
        backgroundColor: '#5531A9',
        richText: `
        <p>Reef Finance is a liquidity aggregator and multi-chain smart yield engine that allows the integration of any DeFi protocol. It has been created with Polkadot, and shares its security model across the ecosystem while enabling cross-chain integrations.</p>
        <p>Reef simplifies the DeFi ecosystem for all users.</p>
        `,
        url: 'https://reef.finance/',
        category: 'polkadot'
    },
    {
        active: false,
        imgUrl: Polkastarter,
        backgroundColor: 'white',
        richText: `
            <p>Polkastarter is a permissionless DEX built for cross-chain token pools and auctions, enabling projects to raise capital on a decentralized and interoperable environment based on Polkadot. With Polkastarter, decentralized projects will be able to raise and exchange capital cheap and fast.</p>
        `,
        url: 'https://www.polkastarter.com/',
        category: 'polkadot'
    },
    {
        active: false,
        imgUrl: Paralink,
        backgroundColor: 'white',
        richText: `
            <p>Paralink is a real world data ingress for cross chain applications. Paralink will act as a data bridge between blockchains and real world data. The purpose of the platform is to collect, validate, aggregate and interpolate real-world datasets and feed them into smart contracts on Ethereum and Polkadot.</p>
        `,
        url: 'https://paralink.network/',
        category: 'polkadot'
    },
    {
        active: true,
        imgUrl: Polkadex,
        backgroundColor: '#172026',
        richText: `
            <p>Polkadex, The trading engine for web3 and Defi. A fully decentralized, peer-peer, orderbook based cryptocurrency exchange for the Defi ecosystem in Substrate. Our mission is to build the perfect decentralized exchange. We envision a world of financial inclusion, creating bridges that transfer value from traditional money markets to a decentralized store of value and trust, thereby preparing the world for a tokenized future.</p>
        `,
        url: 'https://www.polkadex.trade/',
        category: 'polkadot'
    },
    {
        active: false,
        imgUrl: Sigmadex,
        backgroundColor: '#000',
        richText: `
            <p>Decentralized perpetual liquidity protocol infused with game theory.</p>
        `,
        url: 'https://sigmadex.org/',
        category: 'polkadot'
    },
    {
        active: true,
        imgUrl: Zeitgeist,
        backgroundColor: '#000',
        richText: `
            <p>Zeitgeist is an evolving blockchain for prediction markets and futarchy, built on Substrate / Polkadot.</p>
        `,
        url: 'https://zeitgeist.pm/',
        category: 'polkadot'
    },
    {
        active: false,
        imgUrl: Cere,
        backgroundColor: '#000',
        richText: `
            <p>The decentralized data & finance cloud for enterprises.</p>
        `,
        url: 'https://cere.network/',
        category: 'polkadot'
    },
    {
        active: false,
        imgUrl: Raze,
        backgroundColor: '#000',
        richText: `
            <p>Raze Network is a substrate-based cross-chain privacy protocol for the Polkadot Ecosystem.</p>
        `,
        url: 'https://raze.network/',
        category: 'polkadot'
    },
    {
        active: true,
        imgUrl: Parastate,
        backgroundColor: '#00537D',
        richText: `
            <p>ParaState aims to provide an industry standard, highly optimized and extensible smart contract execution environment, while maintaining Ethereum compatibility.</p>
        `,
        url: 'https://www.parastate.io/',
        category: 'polkadot'
    },
    {
        active: false,
        imgUrl: Parami,
        backgroundColor: '#fff',
        richText: `
            <p>The Parami protocol is a substrate based cross-chain DID platform.</p>
        `,
        url: 'https://parami.io/',
        category: 'polkadot'
    },
    {
        active: true,
        imgUrl: Solana,
        backgroundColor: '#000',
        richText: `
            <p>Solana is a fast, secure, and censorship resistant blockchain providing the open infrastructure required for global adoption.</p>
        `,
        url: 'https://solana.com/',
        category: 'solana'
    },
    {
        active: true,
        imgUrl: Synthetify,
        backgroundColor: '#fff',
        richText: `
            <p>Synthetify is a decentralized synthetic assets exchange built on Solana, providng the ultimate trading experiences.</p>
        `,
        url: 'https://synthetify.io/',
        category: 'solana'
    },
    {
        active: true,
        imgUrl: Apricot,
        backgroundColor: '#1A1A1A',
        richText: `
            <p>Apricot is a next generation lending protocol on Solana.</p>
        `,
        url: 'https://apricot.one/',
        category: 'solana'
    },
    {
        active: true,
        imgUrl: Solanium,
        backgroundColor: '#000',
        richText: `
            <p>Solanium is the premier launchpad in the Solana eco-system for public based capital raising.</p>
        `,
        url: 'https://www.solanium.io/',
        category: 'solana'
    },
    {
        active: true,
        imgUrl: Mercurial,
        backgroundColor: '#fff',
        richText: `
            <p>Mercurial is building new liquidity systems to maximise the utility and yield of stable assets on Solana.</p>
        `,
        url: 'https://mercurial.finance/',
        category: 'solana'
    },
    {
        active: true,
        imgUrl: Solrise,
        backgroundColor: '#fff',
        richText: `
            <p>Solrise is an easy to use decentralized fund management and investment protocol on Solana.</p>
        `,
        url: 'https://solrise.finance/',
        category: 'solana'
    },
    {
        active: true,
        imgUrl: Dexlab,
        backgroundColor: '#fff',
        richText: `
            <p>Dexlab is a decentralized exchange where the best Solana projects mint and list their tokens.</p>
        `,
        url: 'https://www.dexlab.space/',
        category: 'solana'
    },
    {
        active: true,
        imgUrl: Hedgehog,
        backgroundColor: '#fff',
        richText: `
            <p>Hedgehog is a lightning fast prediction market that generates passive yield for LPs while providing users a seamless experience.</p>
        `,
        url: 'http://hedgehog.markets/',
        category: 'solana'
    },
    {
        active: true,
        imgUrl: SolRazr,
        backgroundColor: '#000',
        richText: `
            <p>SolRazr is the first decentralized developer ecosystem for Solana.</p>
        `,
        url: 'https://solrazr.com/',
        category: 'solana'
    },
    {
        active: true,
        imgUrl: Irisnet,
        backgroundColor: '#000',
        richText: `
            <p>IRISnet (a.k.a IRIS Hub) is designed to be the foundation for the next generation distributed applications. Built with Cosmos-SDK, IRIS Hub enables cross-chain interoperability through a unified service model, while providing a variety of modules to support DeFi applications.</p>
        `,
        url: 'https://www.irisnet.org/',
        category: 'cosmos'
    },
    {
        active: true,
        imgUrl: Akash,
        backgroundColor: '#000',
        richText: `
            <p>The DeCloud for DeFi, and the world's first decentralized cloud computing marketplace.</p>
        `,
        url: 'https://akash.network/',
        category: 'cosmos'
    },
    {
        active: true,
        imgUrl: Sentinel,
        backgroundColor: '#000',
        richText: `
            <p>A modern VPN backed by blockchain anonymity and security.</p>
        `,
        url: 'https://sentinel.co/',
        category: 'cosmos'
    },
    {
        active: true,
        imgUrl: Sifchain,
        backgroundColor: '#446CA8',
        richText: `
            <p>Sifchain is the omni-chain solution for DEXes. More performant, more robust cryptoeconomics for trading and security, more flexible trading capabilities, an extensible omni-chain roadmap and eventual true DAO governance. Sifchain is the future of DeFi.</p>
        `,
        url: 'https://sifchain.finance/',
        category: 'cosmos'
    },
    {
        active: true,
        imgUrl: Sienna,
        backgroundColor: '#fff',
        richText: `
            <p>Sienna is a privacy-first, decentralized financing platform where you can privately swap, lend and convert your ERC-20 tokens.</p>
        `,
        url: 'https://sienna.network/',
        category: 'cosmos'
    },
]

export default portfolioItems