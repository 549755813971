import * as React from "react"
import styled from 'styled-components'

const StyledGrid = styled.section`
	max-width: 1000px;
    margin: 0 auto;

	display: grid;
	grid-template-columns: repeat(3, 320px);
	column-gap: 16px;
	row-gap: 16px;

	@media (max-width: 992px) {
		grid-template-columns: repeat(2, 320px);
	}

	@media (max-width: 656px) {
		grid-template-columns: 100%;
		column-gap: 0;
		row-gap: 4.923vw;
	}
`

// @media (max-width: 965px) {
// 	grid-template-columns: 30vw 30vw;

// }

// @media (max-width: 768px) {
// 	grid-template-columns: 89.443vw;
// 	grid-template-rows: auto;
// 	column-gap: 0;
// 	row-gap: 10.667vw;
// }

const PortfolioGrid = ({ children }) => {
	return (
		<>
			<StyledGrid>
                { children }
            </StyledGrid>
		</>
	)
}

export default PortfolioGrid
