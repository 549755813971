import * as React from "react"
import styled from 'styled-components'

import Seo from './../components/Seo.js'
import Layout from './../components/layout.js'
import BoxAnimation from './../components/BoxAnimation.js'
import PortfolioGrid from '../components/PortfolioGrid.js'

import PortfolioItem from './../_partials/PortfolioItem.js'

import portfolioItems from './../portfolio.js'

const StyledPortfolio = styled.section`
	display: flex;
	justify-content: space-around;
	padding: 160px 0 280px;
    flex-direction: column;

    @media (max-width: 656px) {
		font-size: 3.692vw;
		padding: 24.615vw 0 36.923vw;
	}
`

const StyledPortfolioHeading = styled.h3`
    width: 100%;
    max-width: 992px;
    margin: 60px auto 16px;
    font-weight:300;
    font-size:1.5em;

    @media (max-width: 992px) {
        margin-left:64px;
	}
    @media (max-width: 656px) {
        margin-left:20px;
	}
`

const PortfolioPage = ({ location: { origin } }) => {
    const cleanPortfolioItems = portfolioItems.filter( ({ active }) => active)

    const mainPortfolioItems = cleanPortfolioItems.filter( ({ category }) => category === 'main')
    const gamingPortfolioItems = cleanPortfolioItems.filter( ({ category }) => category === 'gaming')
    const polkadotPortfolioItems = cleanPortfolioItems.filter( ({ category }) => category === 'polkadot')
    const solanaPortfolioItems = cleanPortfolioItems.filter( ({ category }) => category === 'solana')
    const cosmosPortfolioItems = cleanPortfolioItems.filter( ({ category }) => category === 'cosmos')

	return (
		<>
            <Seo currentPath={origin}/>
			<Layout>
				<StyledPortfolio>
                    <StyledPortfolioHeading>
                        Main
                    </StyledPortfolioHeading>
                    <PortfolioGrid>
                        { mainPortfolioItems.map( ({
                            imgUrl,
                            backgroundColor,
                            richText,
                            url,
                        }, index) => (
                            <PortfolioItem
                                key={index}
                                imgUrl={imgUrl}
                                backgroundColor={backgroundColor}
                                richText={richText}
                                url={url}
                            />
                        ))}
                    </PortfolioGrid>
                    <StyledPortfolioHeading>
                        Gaming, NFT, and Metaverse
                    </StyledPortfolioHeading>
                    <PortfolioGrid>
                        { gamingPortfolioItems.map( ({
                            imgUrl,
                            backgroundColor,
                            richText,
                            url,
                        }, index) => (
                            <PortfolioItem
                                key={index}
                                imgUrl={imgUrl}
                                backgroundColor={backgroundColor}
                                richText={richText}
                                url={url}
                            />
                        ))}
                    </PortfolioGrid>
                    <StyledPortfolioHeading>
                        Solana Ecosystem
                    </StyledPortfolioHeading>
                    <PortfolioGrid>
                        { solanaPortfolioItems.map( ({
                            imgUrl,
                            backgroundColor,
                            richText,
                            url,
                        }, index) => (
                            <PortfolioItem
                                key={index}
                                imgUrl={imgUrl}
                                backgroundColor={backgroundColor}
                                richText={richText}
                                url={url}
                            />
                        ))}
                    </PortfolioGrid>
                    <StyledPortfolioHeading>
                        Polkadot Ecosystem
                    </StyledPortfolioHeading>
                    <PortfolioGrid>
                        { polkadotPortfolioItems.map( ({
                            imgUrl,
                            backgroundColor,
                            richText,
                            url,
                        }, index) => (
                            <PortfolioItem
                                key={index}
                                imgUrl={imgUrl}
                                backgroundColor={backgroundColor}
                                richText={richText}
                                url={url}
                            />
                        ))}
                    </PortfolioGrid>
                    <StyledPortfolioHeading>
                        Cosmos Ecosystem
                    </StyledPortfolioHeading>
                    <PortfolioGrid>
                        { cosmosPortfolioItems.map( ({
                            imgUrl,
                            backgroundColor,
                            richText,
                            url,
                        }, index) => (
                            <PortfolioItem
                                key={index}
                                imgUrl={imgUrl}
                                backgroundColor={backgroundColor}
                                richText={richText}
                                url={url}
                            />
                        ))}
                    </PortfolioGrid>
                    <BoxAnimation/>
				</StyledPortfolio>
            </Layout>
		</>
	)
}

export default PortfolioPage
